<template>
  <div class="reward-address">
    <div class="wrapper">
      <div class="title font-md">
        <span>배송지 정보 수정</span>
      </div>
      <div class="desc font-sm">내용을 입력하시고 저장하기 버튼을 눌러주세요.</div>
      <div class="form">
        <div class="form-group">
          <label :for="`${component.name}Address`" class="font-sm">
            <span>주소 </span>
          </label>
          <Address :id="`${component.name}Address`" :zoneCode.sync="state.form.postno" :address1.sync="state.form.addr1" :address2.sync="state.form.addr2"/>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label :for="`${component.name}Receiver`" class="font-sm">
                <span>받는 분 </span>
              </label>
              <input :id="`${component.name}Receiver`" type="text" class="form-control border-focus-point font-sm" v-model="state.form.receiveName" @keyup.enter="save()"/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label :for="`${component.name}Hp`" class="font-sm">
                <span>연락처 </span>
              </label>
              <Phone :id="`${component.name}Hp`" :value.sync="state.form.hp" placeholder="ex) 01012345678" :enter="save"/>
            </div>
          </div>
        </div>
        <div class="form-group mb-0">
          <label :for="`${component.name}Memo`" class="font-sm">
            <span>배송 메모 </span>
          </label>
          <input :id="`${component.name}Memo`" type="text" class="form-control border-focus-point font-sm" v-model="state.form.memo" @keyup.enter="save()"/>
        </div>
      </div>
    </div>
    <div class="action">
      <button :id="`${component.name}Submit`" class="btn btn-point btn-block font-sm" @click="save()">{{ modalParams.temp ? "입력하기" : "저장하기" }}</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import mixin from "@/scripts/mixin";
import Address from "@/components/Address";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalRewardAddress";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Phone, Address, Number},
  setup() {
    const component = new Component(() => {
      state.form.addr1 = modalParams.detail.addr1;
      state.form.addr2 = modalParams.detail.addr2;
      state.form.postno = modalParams.detail.postno;
      state.form.receiveName = modalParams.detail.receiveName;
      state.form.hp = modalParams.detail.hp;
      state.form.memo = modalParams.detail.memo;
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      form: {
        addr1: "",
        addr2: "",
        postno: "",
        receiveName: "",
        hp: "",
        memo: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const save = () => {
      const args = lib.getRenewed(state.form);

      if (!args.addr1 || !args.addr2 || !args.postno) {
        document.getElementById(`${component.name}Address`).focus();
        return store.commit("setSwingMessage", "주소를 입력해주세요.");
      } else if (!args.receiveName) {
        document.getElementById(`${component.name}Receiver`).focus();
        return store.commit("setSwingMessage", "받는 분 이름을 입력해주세요.");
      } else if (!args.hp) {
        document.getElementById(`${component.name}Hp`).focus();
        return store.commit("setSwingMessage", "연락처를 입력해주세요.");
      }

      args.investorSeq = modalParams.detail.investorSeq;

      http.put("/api/participant/reward/change-shipping", args).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "주문자 정보를 저장하였습니다.");
          }
        });
      }).catch(httpError(() => {
      }));
    };

    onMounted(() => {
      document.getElementById(`${component.name}CardNumber1`)?.focus();
    });

    return {component, state, modalParams, save};
  }
});
</script>

<style lang="scss" scoped>
.reward-address {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      .form-group {
        margin-bottom: $px20;

        input[type=text], input[type=password], select {
          height: $formHeight;
          width: 100%;
        }

        > .wrapper {
          display: table;
          table-layout: fixed;
          margin: 0 $px-5;
          width: 100%;

          .td {
            display: table-cell;
            padding: 0 $px5;

            &.quarter {
              width: 25%;
            }

            &.half {
              width: 50%;
            }
          }
        }
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>